export default function () {
  
  const keydownEnterNav = (e: { key: string }, handler: Function) => {
    // console.log("keydownEnterNav e: ", e)
    if (e.key === "Enter") {
      handler()
    }
  }

  // const handleSpace = (e: { preventDefault: () => void }) => {
  //   e.preventDefault()
  // }
  const preventSpace = (e: { target: { value: string }, preventDefault: () => void }, sequential: boolean = false) => { // use to prevent input event if space is pressed
    if (sequential) {
      if (e.target.value.at(-1) === " ") {
        e.preventDefault()
      }
    } else {
      e.preventDefault()
    }

    // if (e.target.value.at(-1) === " " && sequential) {
    //   e.preventDefault()
    // }
    // e.preventDefault()
  }

  const handleSpace = (value: string) => {
    return value.replace(/\s/g, "") // removes all whitespace
  }

  const handleNum = (char: string) => {  
    return parseInt(char.toString().replace(/\D/g, "")); // removes all non-numeric characters
  }

  const handleVin = (vin: string) => {
    return vin.replace(/[^a-zA-Z0-9]+$/g, "").toUpperCase() // removes all non-alphanumeric characters and converts to uppercase
  }

  const handleSsn = (ssn: string) => {
    ssn = ssn.replace(/\D/g, "")
    const x: any = ssn.match(/(\d{0,3})(\d{0,2})(\d{0,4})/)
    ssn = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '')

    return ssn
  }

  // NOTE: this is handled in the component props instead
  // const handleName = (name: string) => {
  //   return name.replace(/  +/g, " ") // replaces multiple spaces with a single space
  // }

  const handlePhone = (phone: string) => {
    phone = phone.replace(/\D/g, "") // removes all non-numeric characters

    const x: any = phone.match(/(\d{0,3})(\d{0,3})(\d{0,4})/) // matches phone number to format (xxx) xxx-xxxx
    phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '') // adds formatting to phone number

    return phone
  }

  return {
    keydownEnterNav,
    preventSpace,
    handleSpace,
    handleNum,
    handleVin,
    handleSsn,
    // handleName,
    handlePhone,
  }
}